import React from 'react';

const YouTubeVideos = ({ videos }) => {
  return (
    <div className="row mt-24">
      {videos.map((src, index) => (
        <div className="col-md-4" data-aos="fade-up" key={index}>
          <div
            className="new-container-box info-box shadow-box"
            style={{
              padding: 0,
              borderRadius: '20px',
              height: '200px',
              marginBottom: '20px',
            }} // Increase height here
          >
            <iframe
              width="100%"
              height="100%"
              src={src}
              title={`YouTube video player ${index + 1}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              style={{
                display: 'block',
                width: '100%',
                height: '100%',
                borderRadius: '20px',
              }}
            ></iframe>
          </div>
        </div>
      ))}
    </div>
  );
};

export default YouTubeVideos;
