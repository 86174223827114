"use client";

import { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { addMonths, subMonths, format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isToday, isSameDay } from "date-fns";
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Paper, Typography } from "@mui/material";
import { useUser } from "@clerk/nextjs";
import { getStreak } from '../../personal-portfolio/utils/dataFetch';

export default function Calendar() {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [streakCount, setStreakCount] = useState(1);
  const [streakDates, setStreakDates] = useState([]);

  const { user } = useUser();

  useEffect(() => {
    if (user) {
      getStreak(user?.emailAddresses[0].emailAddress).then(response => {
        const streakData = response?.data?.view_history[0];
        if (streakData) {
          // Parse and set the streak dates from page_view_history
          const pageViewHistory = JSON.parse(streakData.page_view_history);
             // @ts-ignore 
          const formattedDates = pageViewHistory.map(dateString => new Date(dateString));
          setStreakCount(streakData.streak_count || 1);
          setStreakDates(formattedDates);
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }, [user]);

  const prevMonth = () => setCurrentMonth(subMonths(currentMonth, 1));
  const nextMonth = () => setCurrentMonth(addMonths(currentMonth, 1));

  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(currentMonth);
  const monthDays = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
   // @ts-ignore 
  const isStreakDay = (date) => 
    streakDates.some(streakDate => isSameDay(streakDate, date));

  return (
    <Card className="w-full max-w-md mx-auto shadow-none">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-2xl font-bold">
          {format(currentMonth, "MMMM yyyy")}
        </CardTitle>
        <div className="space-x-2">
          <Button variant="outline" size="icon" onClick={prevMonth}>
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Previous month</span>
          </Button>
          <Button variant="outline" size="icon" onClick={nextMonth}>
            <ChevronRight className="h-4 w-4" />
            <span className="sr-only">Next month</span>
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-7 gap-2 text-center">
          {weekDays.map((day) => (
            <div key={day} className="font-semibold text-sm py-2">
              {day}
            </div>
          ))}
          {monthDays.map((day) => (
            <div
              key={day.toString()}
              className={`p-2 text-sm rounded-full ${
                !isSameMonth(day, currentMonth)
                  ? "text-muted-foreground"
                  : isToday(day)
                  ? "bg-primary text-primary-foreground"
                  : isStreakDay(day)
                  ? "bg-[#FFEB8C] text-[#4A4A4A] font-semibold" // Highlight streak days with a bold style
                  : ""
              }`}
            >
              {format(day, "d")}
            </div>
          ))}
        </div>
      </CardContent>
      {/* Streak Box */}
      <div>
        <Paper
          elevation={0}
          sx={{
            height: 120,
            borderRadius: '9px',
            backgroundColor: '#FFEB8C',
            padding: 2,
            marginTop: '-10px',
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0
          }}
        >
          <Typography sx={{ color: '#FFA600', fontWeight: '800' }}>
            {user?.firstName},{' '}
            <span
              style={{
                fontWeight: '700',
                fontStyle: 'italic',
              }}
            >
              You are on Track!
            </span>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Keep the momentum going and watch your streak grow.
          </Typography>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 500,
              color: '#4A4A4A',
            }}
          >
            Streak Count: {streakCount}
          </Typography>
        </Paper>
      </div>
    </Card>
  );
}
