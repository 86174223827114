import React, { useState } from 'react';
import { Triangle } from './Triangle';

interface PopupProps {
  steps: {
    title: string;
    description: string;
    position?: {
      popup?: {
        top?: number | string;
        left?: number | string;
        bottom?: number | string;
        right?: number | string;
      };
      triangle?: {
        position: 'top' | 'right' | 'bottom' | 'left';
        offset?: number;
      };
    };
  }[];
  onClose: () => void;
}

export function TutorialPopup({ steps, onClose }: PopupProps) {
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onClose();
    }
  };

  const handleSkip = () => {
    onClose();
  };

  const currentPosition = steps[currentStep].position?.popup || {};
  const trianglePosition = steps[currentStep].position?.triangle;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[10000]">
      <div 
        className=" text-white rounded-lg p-6 w-[320px] relative"
        style={{
          position: 'fixed',
          backgroundColor:"#507AE9",
          margin: 0,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
          ...currentPosition
        }}
      >
        {/* Triangle */}
        {trianglePosition && (
          <Triangle 
            position={trianglePosition.position}
            offset={trianglePosition.offset}
          />
        )}

        {/* Step indicator */}
        <div className="absolute top-4 left-4 text-sm text-blue-200">
          {currentStep + 1}/{steps.length}
        </div>

        {/* Content */}
        <div className="mt-8 mb-6">
          <h2 className="text-xl font-semibold mb-2">
            {steps[currentStep].title}
          </h2>
          <p className="text-blue-100">
            {steps[currentStep].description}
          </p>
        </div>

        {/* Navigation dots */}
        <div className="flex justify-center gap-2 mb-4">
          {steps.map((_, index) => (
            <div
              key={index}
              className={`h-2 w-2 rounded-full ${
                index === currentStep ? 'bg-white' : 'bg-blue-300'
              }`}
            />
          ))}
        </div>

        {/* Buttons */}
        <div className="flex justify-end gap-4">
          <button
            onClick={handleSkip}
            className="px-4 py-2 text-sm hover:bg-blue-700 rounded"
          >
            Skip tour
          </button>
          <button
            onClick={handleNext}
            className="px-4 py-2 text-sm bg-white text-blue-600 rounded hover:bg-blue-50"
          >
            {currentStep === steps.length - 1 ? 'Finish' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
}