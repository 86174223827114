import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import Image from 'next/image';
import ads from '../public/assets/icons/ads1.png';
import { useRouter } from 'next/router';
import Icon from '@mui/icons-material/SettingsEthernet'; 

const Advertisement = () => {
  const router = useRouter();
  const handleClick = () => {
    router.push('/roadmaps'); // Redirects to the /roadmaps page
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: { xs: 'center', sm: 'center' },
        justifyContent: { xs: 'center', sm: 'space-between' },
        padding: '16px',
        // border: '1px solid #E0E0E0',
        borderRadius: '8px',
        // boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05)',
        backgroundColor: '#fff',
        marginBottom: '1px',
        marginTop: "-30px",
        flexDirection: { xs: 'column', sm: 'row' },
        textAlign: { xs: 'center', sm: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: { xs: 'column', sm: 'row' },
          marginBottom: { xs: '16px', sm: '0' },
          textAlign: { xs: 'center', sm: 'left' },
        }}
      >
        <Image
          src={ads}
          alt="Live Icon"
          width={60}
          height={60}
          style={{ maxWidth: '100%', height: 'auto' }}
        />
        <Box sx={{ marginLeft: { xs: '0', sm: '16px' }, marginTop: { xs: '8px', sm: '0' } }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '16px', sm: '18px' },
            }}
          >
           Get Personalized Roadmap to Achieve your Goals with AirEdify
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: '#555',
              fontSize: { xs: '14px', sm: '16px' },
              marginTop: { xs: '8px', sm: '4px' },
            }}
          >
            Explore & Learn, build resumes, apply for jobs.
          </Typography>
        </Box>
      </Box>
      <Button
      variant="outlined"
      startIcon={<Icon />} // Icon placed before the text
      sx={{
        textTransform: 'none',
        fontWeight: '600', // Semi-bold text
        backgroundColor: '#fff',
        color: '#000',
        borderColor: '#000',
        border: '1px solid', // Border with 1px width
        borderRadius: '12px',
        padding: { xs: '8px 16px', sm: '5px 20px' },
        fontSize: { xs: '14px', sm: '16px' },
      }}
      onClick={handleClick}
    >
      Get Started
    </Button>
    </Box>
  );
};

export default Advertisement;
