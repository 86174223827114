import { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  Grid,
  Avatar,
  Typography,
  IconButton,
  Divider,
} from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getArticleData } from 'apps/personal-portfolio/utils/dataFetch';
import { useRouter } from 'next/router';
import CustomLoader from '../components/loaders/CustomLoaderFeeds';

const Feeds = () => {
  const [articleData, setArticleData] = useState([]);
  const [loading, setLoading] = useState(true);
  const router = useRouter();

  useEffect(() => {
    getArticleData('articles').then((data) => {
      setArticleData(data?.data?.articles);
      setLoading(false); // Set loading to false after data is fetched
    });
  }, []);

  return (
    <Paper elevation={0} sx={{ position: 'relative', borderRadius: '9px', padding: 2 }}>
      {/* Loader positioned at the top */}
      {loading && (
    <Box
      sx={{
      marginTop: "20px"
      }}
    >
      <CustomLoader /> {/* Your custom loader */}
    </Box>
  )}

      {/* Main Content */}
      {!loading && (
        articleData.slice(0, 5).map((article, index) => (
          <Box
            key={index}
            sx={{
              mb: 1,
              padding: '10px',
              cursor: 'pointer', 
              borderRadius: "10px",
              '&:hover': {
                backgroundColor: '#DFE1E7', // Change background color on hover
              },
            }}
            onClick={() => router.push(`/articles/${article.notion_id}`)}
          >
            <Grid container spacing={2}>
              <Grid item xs={1} style={{ marginRight: '20px' }}>
                <Avatar
                  sx={{ width: 40, height: 40 }}
                  src={article.image_url} // Use article image
                />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h6" fontWeight="bold">
                  {article.title}
                </Typography>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <BusinessCenterIcon fontSize="small" />
                    <Typography variant="caption" ml={0.5}>
                      {article.companyType}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <LocationOnIcon fontSize="small" />
                    <Typography variant="caption" ml={0.5}>
                      {article.location}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">{article.jobType}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <IconButton>
                  <BookmarkBorderIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Paper
              elevation={0}
              sx={{
                borderRadius: '9px',
                padding: 2,
                mt: 2,
                backgroundColor: '#f9f9f9',
              }}
            >
              <Typography variant="body2" color="text.secondary">
                {article.description}
              </Typography>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ mt: 2 }}
              >
                <Typography variant="caption" color="text.secondary">
                  {article.time}
                </Typography>
                <Box>
                  <IconButton>
                    <SearchIcon
                      sx={{
                        backgroundColor: '#e0e7ff',
                        borderRadius: '50%',
                        padding: '8px',
                      }}
                    />
                  </IconButton>
                  <IconButton>
                    <ArrowForwardIcon
                      sx={{
                        backgroundColor: '#e0e7ff',
                        borderRadius: '50%',
                        padding: '8px',
                      }}
                    />
                  </IconButton>
                </Box>
              </Grid>
            </Paper>

            <Divider sx={{ my: 2 }} />
          </Box>
        ))
      )}
    </Paper>
  );
};

export default Feeds;
