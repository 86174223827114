import React from 'react';

interface TriangleProps {
  position: 'top' | 'right' | 'bottom' | 'left';
  offset?: number;
  size?: number;
  color?: string;
}

export function Triangle({ 
  position = 'top', 
  offset = 20, 
  size = 12,
  color = '#2563eb' // blue-600
}: TriangleProps) {
  const getTriangleStyles = (): React.CSSProperties => {
    const baseStyle: React.CSSProperties = {
      position: 'absolute',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      zIndex : 10000
    };

    const positions = {
      top: {
        top: -size,
        left: offset,
        borderWidth: `0 ${size}px ${size}px ${size}px`,
        borderColor: `transparent transparent ${color} transparent`,
      },
      right: {
        top: offset,
        right: -size,
        borderWidth: `${size}px 0 ${size}px ${size}px`,
        borderColor: `transparent transparent transparent ${color}`,
      },
      bottom: {
        bottom: -size,
        left: offset,
        borderWidth: `${size}px ${size}px 0 ${size}px`,
        borderColor: `${color} transparent transparent transparent`,
      },
      left: {
        top: offset,
        left: -size,
        borderWidth: `${size}px ${size}px ${size}px 0`,
        borderColor: `transparent ${color} transparent transparent`,
      },
    };

    return { ...baseStyle, ...positions[position] };
  };

  return <div style={getTriangleStyles()}  className='z-[100000]'/>;
}