import React from 'react';
import AnimatedLogo from '../public/assets/lottie/logo-animated.gif';
// import AnimatedLogo from '../public/assets/harsh.png';
import Image from 'next/image';

function Loader() {
    return ( 
        <div className="loader" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            backgroundColor: '#F7F9FC', // Optional: semi-transparent background
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999
        }}>
            <Image src={AnimatedLogo} alt="AirEdify" 
                
                style={{
                    display: 'block'
                }}/>
        </div>
     );
}

export default Loader;