// LastRow.jsx
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Box, Typography } from '@mui/material';

const LastRow = () => {
  // Hardcoded data
  const quickFacts = [
    { count: '2', label: 'Years Experience' },
    { count: '100+', label: 'Projects Completed' },
    { count: '50+', label: 'Happy Students' },
  ];

  const contact = {
    heading: 'Book Certified Teachers & Trainers',
    button: {
      link: '/contact',
      icon: 'button-light.png', // or 'button-dark.png'
    },
  };

  const theme = 'light'; // or 'dark'

  const getThemedContent = (icon) => `/assets/${icon.replace('button-', `button-${theme}-`)}`;

  return (
    <div className="row mt-24">
      {/* First Column */}
      <div className="col-md-6" data-aos="zoom-in">
      <Link className="overlay-link" href="/contact" />
        <Box
          className="about-client-box info-box shadow-box"
          sx={{ position: 'relative' }}
        >
          <Image
            src="/assets/bg1.png"
            alt="BG"
            className="bg-img"
            width={800} // Adjust the width as needed
            height={600} // Adjust the height as needed
          />
          <Box
            className="clients d-flex align-items-start gap-24 justify-content-center"
            sx={{ position: 'relative', zIndex: 1 }}
          >
            {quickFacts.map((item, index) => (
              <Box className="client-item" key={index} sx={{ textAlign: 'center' }}>
                <Typography variant="h1">{item.count}</Typography>
                <Typography
                  variant="body1"
                  component="b"
                  dangerouslySetInnerHTML={{ __html: item.label }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </div>

            
      {/* Second Column */}
      <div className="col-md-6" data-aos="zoom-in">
      <Link className="overlay-link" href="/contact" />
        <Box
          className="about-contact-box info-box shadow-box"
          sx={{ position: 'relative' }}
        >
          <Image
            src="/assets/bg1.png"
            alt="BG"
            className="bg-img"
            width={800} // Adjust the width as needed
            height={600} // Adjust the height as needed
          />
          <Image
            src="/assets/icons/icon2.png"
            alt="Icon"
            className="star-icon"
            width={40} // Adjust the size as needed
            height={40} // Adjust the size as needed
          />
          <Typography
            variant="h1"
            dangerouslySetInnerHTML={{ __html: contact.heading }}
          />
          <Link href="/contact" className="about-btn">
          <img
              src={`/assets/icons/icon-2.svg`}
              alt="button"
            />
          </Link>
        </Box>
      </div>
    </div>
  );
};

export default LastRow;
