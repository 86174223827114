import React from 'react';
import { Stack, Typography, Paper, Box } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Image from 'next/image'; // Assuming you're using Next.js for image optimization
import CertificateImage from '../public/assets/background/Certification.png';
import classThumbnail from '../public/assets/background/classThumbnail.png';

const LiveClass = () => {
  return (
    <Box
      sx={{
        width: { xs: '90%', sm: 300 }, // Match width settings from ProfileCard
        mx: 'auto', // Center the card
        textAlign: 'center', // Ensure the content is centered
        mt: 2, // Add some margin on top
      }}
    >
      {/* Live Indicator */}
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
        <FiberManualRecordIcon sx={{ color: 'red', fontSize: 16 }} />
        <Typography sx={{ fontWeight: 'bold' }}>Now LIVE</Typography>
      </Stack>

      {/* Class Thumbnail */}
      <Paper
        elevation={0}
        sx={{
          height: { xs: 150, sm: 200 }, // Responsive height
          width: '100%',
          mt: 2,
          borderRadius: '9px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* Actual Image Box */}
          <Box
            sx={{
              height: '95%',
              width: '95%',
              position: 'relative',
            }}
          >
            <Image
              src={classThumbnail} // Path to your class thumbnail image
              alt="Class Thumbnail"
              layout="fill"
              objectFit="contain" // Ensure the image scales properly
            />
          </Box>
        </Box>
      </Paper>

      {/* Certificate Image */}
      <Paper
        elevation={0}
        sx={{
          height: { xs: 150, sm: 200 }, // Responsive height
          width: '100%',
          mt: 2,
          borderRadius: '9px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* Actual Image Box */}
          <Box
            sx={{
              height: '90%',
              width: '90%',
              position: 'relative',
            }}
          >
            <Image
              src={CertificateImage} // Path to your certificate image
              alt="Certification"
              layout="fill"
              objectFit="contain" // Ensure the image scales properly
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default LiveClass;
