import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useRouter } from 'next/router';
import { getStreak } from '../../personal-portfolio/utils/dataFetch';
import { useUser } from '@clerk/nextjs';

const Days = () => {
  const router = useRouter();
  const { user } = useUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen is mobile

  const handleClick = () => {
    router.push('/roadmaps'); // Redirects to the /roadmaps page
  };

  const [streak_count, setStreakCount] = useState(1);
  const targetDay = 90; // Target day for completion

  useEffect(() => {
    if (user) {
      getStreak(user?.emailAddresses[0].emailAddress).then((data) => {
        setStreakCount(data?.data.view_history[0]?.streak_count);
      });
    }
  }, [user]);

  // Calculate progress percentage
  const progressValue = (streak_count / targetDay) * 100;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center" // Center horizontally
      borderRadius="30px"
      padding={isMobile ? '12px' : '8px 16px'} // Adjust padding for mobile
      width="100%" // Full width of the parent
      maxWidth="900px"
      margin="auto"
      marginBottom="60px"
      sx={{
        backgroundColor: '#fff',
        position: 'fixed', // Keep fixed position

        left: '55%',
        right: '50%',
        transform: 'translate(-50%, -50%)', // Adjust to center based on width and height
        zIndex: 1, // Ensure it's on top of other elements
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)', // Soft shadow
        padding: '8px 16px', // Consistent padding
        marginTop: '-20px',
      }}
    >
      <CalendarTodayIcon style={{ marginRight: 8 }} />
      <Typography
        variant={isMobile ? 'body2' : 'body1'} // Smaller text for mobile
        color="textPrimary"
        style={{ marginRight: 8 }}
      >
        Day {streak_count}
      </Typography>
      <Box flexGrow={1} mx={isMobile ? 0 : 2} position="relative">
        <LinearProgress
          variant="determinate"
          value={progressValue} // Dynamically set based on streak count
          style={{
            height: 8,
            borderRadius: 4,
            backgroundColor: '#E0E0E0',
          }}
          sx={{
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#4CAF50', // Green progress bar color
            },
          }}
        />
        <Box
          position="absolute"
          right="0"
          top="-10px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            sx={{
              backgroundColor: '#FFEB8C', // Yellow background
              borderRadius: '50%', // Circular shape
              padding: '6px', // Adjust padding to control the circle size
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              scale: '1.1',
            }}
          >
            <EmojiEventsIcon sx={{ color: '#1E88E5' }} /> {/* Trophy icon */}
          </Box>
          <Typography variant="caption" color="textSecondary" sx={{ mt: 0.5 }}>
            Day {targetDay}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Days;
