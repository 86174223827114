import React, { useState } from 'react';
import { Paper, Box, Avatar, TextField, Typography, Tooltip } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import EventIcon from '@mui/icons-material/Event';
import ArticleIcon from '@mui/icons-material/Article';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import UserButtonComponent from '../components/UserButtonComponent';

const PostInbox = () => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  // Function to handle input focus
  const handleInputFocus = () => {
    setTooltipOpen(true);
  };

  // Function to handle input blur
  const handleInputBlur = () => {
    setTooltipOpen(false);
  };

  return (
    <Paper
      elevation={0}
      sx={{ borderRadius: '9px', padding: 2, marginBottom: '10px' }}
    >
      <Box display="flex" alignItems="center">
        {/* Avatar */}
        <UserButtonComponent />

        {/* Input field with tooltip */}
        <Tooltip
          title="This feature is under development."
          placement="top"
          open={tooltipOpen}
          arrow
        >
          <TextField
            fullWidth
            placeholder="Post Jobs, internships or Events happening"
            InputProps={{
              sx: {
                borderRadius: '30px',
                backgroundColor: '#fff',
                padding: '5px 15px',
                height: '40px',
                marginLeft: "10px"
              },
            }}
            variant="outlined"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
        </Tooltip>
      </Box>

      {/* Icons and text */}
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        marginTop={2}
      >
        {/* Jobs */}
        <Box display="flex" alignItems="center">
          <WorkIcon sx={{ color: '#03A9F4' }} />
          <Typography sx={{ marginLeft: 1, fontWeight: 500 }}>Jobs</Typography>
        </Box>

        {/* Events */}
        <Box display="flex" alignItems="center">
          <EventIcon sx={{ color: '#4CAF50' }} />
          <Typography sx={{ marginLeft: 1, fontWeight: 500 }}>Events</Typography>
        </Box>

        {/* Articles */}
        <Box display="flex" alignItems="center">
          <ArticleIcon sx={{ color: '#9C27B0' }} />
          <Typography sx={{ marginLeft: 1, fontWeight: 500 }}>Articles</Typography>
        </Box>

        {/* Community Post */}
        <Box display="flex" alignItems="center">
          <BusinessCenterIcon sx={{ color: '#FF9800' }} />
          <Typography sx={{ marginLeft: 1, fontWeight: 500 }}>Community Post</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default PostInbox;
