import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Row, Col } from 'reactstrap';
import masterclass from '../public/assets/icons/masterclass.png';
import arrow from '../public/assets/icons/icon-2.svg';
import LinearProgressBar from './LinearProgressBar';

const MasterclassComponent = () => {
  const theme = 'light'; // Example theme, you can change or remove this if not needed

  return (
    <div className="row mt-24">
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Image
          src={masterclass}
          alt="Recommendation Icon"
          width={54}
          height={54}
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
        />
        <h4 style={{ margin: 0, fontWeight: '600', color: '#FF4853' }}>
          Masterclass Booking Ends in 1 Hour!
        </h4>
      </div>
      <div style={{ marginBottom: '20px', marginTop: '10px' }}>
        <LinearProgressBar />
      </div>

      {/* Suprava Section */}
      <div className="col-md-6" data-aos="zoom-in">
        <div className="about-contact-box info-box shadow-box">
          <Link className="overlay-link" href="/classes" />
          <img src="/assets/bg1.png" alt="BG" className="bg-img" />
          <img src="/assets/icons/icon2.png" alt="Icon" className="star-icon" />
          <Row>
            <Col md="6" data-aos="zoom-in">
              <img
                src="/assets/suprava.png"
                alt="Icon"
                style={{ marginTop: '-50px' }}
              />
            </Col>
            <Col md="6" data-aos="zoom-in">
              <h3
                style={{
                  color: 'red',
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
              >
                LIVE UI/UX Designing
              </h3>
              <h1 style={{ fontWeight: 'bold' }}>Masterclass</h1>
              <h1 style={{ color: "#427BF1" }}>Suprava Saha</h1>
            </Col>
          </Row>
          <Link href="/classes" className="about-btn">
      
            <img
              src={`/assets/icons/icon-2.svg`}
              alt="button"
            />
          </Link>
        </div>
      </div>

      {/* Harsh Section */}
      <div className="col-md-6" data-aos="zoom-in">
        <div className="about-contact-box info-box shadow-box">
          <Link className="overlay-link" href="/classes" />
          <img src="/assets/bg1.png" alt="BG" className="bg-img" />
          <img src="/assets/icons/icon2.png" alt="Icon" className="star-icon" />
          <Row>
            <Col md="6" data-aos="zoom-in">
              <img
                src="/assets/harsh.png"
                alt="Icon"
                style={{ marginTop: '-50px' }}
              />
            </Col>
            <Col md="6" data-aos="zoom-in">
              <h3
                style={{
                  color: 'red',
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
              >
                LIVE Data Analytics Class
              </h3>
              <h1 style={{ fontWeight: 'bold' }}>Masterclass</h1>
              <h1 style={{ color: "#427BF1" }}>Harsh Tiwari</h1>
            </Col>
          </Row>
          <Link href="/classes" className="about-btn">
          <img
              src={`/assets/icons/icon-2.svg`}
              alt="button"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MasterclassComponent;
