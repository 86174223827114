import React, { useState, useEffect } from 'react';
import { Checkbox, Button, TextField, Typography, Paper } from '@mui/material';

const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const [taskInput, setTaskInput] = useState('');

  // Load tasks from local storage on component mount
  useEffect(() => {
    const savedTasks = JSON.parse(localStorage.getItem('tasks')) || [];
    setTasks(savedTasks);
  }, []);

  // Save tasks to local storage whenever they change
  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
  }, [tasks]);

  const addTask = () => {
    if (taskInput.trim() === '') return; // Prevent empty tasks
    const newTask = { id: Date.now(), text: taskInput, completed: false };
    setTasks((prevTasks) => [...prevTasks, newTask]);
    setTaskInput('');
  };

  const toggleTaskCompletion = (taskId) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === taskId ? { ...task, completed: !task.completed } : task
      )
    );
  };

  const removeTask = (taskId) => {
    setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
  };

  return (
    <Paper
      elevation={0}
      style={{
        padding: '16px',
        width: '300px', // Reduced width
        margin: 'auto',
        borderRadius: '12px', // Added border radius
        // marginTop: '-55px',
        marginBottom: "15px"
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          gutterBottom
          style={{ fontSize: '19px', fontWeight: '700', marginBottom: '-5px' }}
        >
          Task
        </Typography>
        <Button
          variant="contained"
          style={{ backgroundColor: '#427BF1', marginLeft: '10px' }}
          onClick={addTask}
        >
          Add Task
        </Button>
      </div>
      <TextField
        variant="outlined"
        label="New Task"
        value={taskInput}
        onChange={(e) => setTaskInput(e.target.value)}
        fullWidth
        margin="normal"
        InputProps={{
          style: {
            borderRadius: '52px',
            maxHeight: '47px',
          },
        }}
      />
      <div style={{ marginTop: '16px' }}>
        {tasks.map((task) => (
          <div key={task.id} style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={task.completed}
              onChange={() => toggleTaskCompletion(task.id)}
            />
            <Typography
              style={{
                textDecoration: task.completed ? 'line-through' : 'none',
                flexGrow: 1,
              }}
            >
              {task.text}
            </Typography>
            <Button
              onClick={() => removeTask(task.id)}
              color="secondary"
              variant="outlined"
              size="small"
            >
              Remove
            </Button>
          </div>
        ))}
      </div>
    </Paper>
  );
};

export default Tasks;
