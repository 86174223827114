import React from 'react';
import { Paper, Typography, IconButton, Button, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import UserButtonComponent from '../components/UserButtonComponent';
import { useRouter } from 'next/router';
import { useUser } from '@clerk/nextjs';

const ProfileCard = () => {
  const router = useRouter(); // Initialize the router

  const handleClick = () => {
    router.push('/resume'); // Redirect to /resume page
  };

  const {user ,isSignedIn} = useUser();
  return (
    <Paper
      elevation={0}
      sx={{
        width: { xs: '90%', sm: 300 }, // 90% width on small screens, 300px on larger
        borderRadius: '9px',
        padding: { xs: 2, sm: 2 }, // Consistent padding
        textAlign: 'center',
        position: 'relative',
        mx: 'auto', // Center the card
      }}
    >
      {/* Profile Title */}
      <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1, textAlign: 'left' }}>
        Profile
      </Typography>

      {/* Edit Icon */}
      <IconButton
        size="small"
        sx={{ position: 'absolute', top: 8, right: 8 }}
        aria-label="edit"
      >
        <EditIcon />
      </IconButton>

      {/* Avatar */}
      <Box
        sx={{
          display: 'inline-block',
          transform: 'scale(2.5)',
          transformOrigin: 'center',
          marginTop: { xs: '10px', sm: '20px' }, // Adjust margin for smaller screens
          marginBottom: { xs: '10px', sm: '20px' },
        }}
      >
        <UserButtonComponent />
      </Box>

      {/* Name */}
      <Typography variant="h6" sx={{ fontWeight: '500', fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
        {isSignedIn ? user.fullName : null }
      </Typography>

      {/* Placeholder Text */}
      <Typography variant="body2" color="textSecondary" sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>
        LeaderBoard will be available soon.
      </Typography>

      {/* View Resume Button */}
      <Button
        variant="contained"
        sx={{
          mt: 2,
          backgroundColor: '#6C8BFF',
          borderRadius: '12px',
          textTransform: 'none',
          boxShadow: 'none', // No shadow by default
          width: '100%', // Full width on mobile
          '&:hover': {
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Shadow on hover
          },
        }}
        onClick={handleClick} // Trigger redirect on click
      >
        View Resume
      </Button>
    </Paper>
  );
};

export default ProfileCard;
