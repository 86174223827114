// components/Loader.tsx

import Lottie from 'react-lottie';
import animationData from '../../public/assets/lottie/air-loader.json';

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <div
      style={{
        // position: 'fixed',
        // top: 0,
        // left: 0,
        // right: 0,
        // bottom: 0,
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        // backdropFilter: 'blur(4px) brightness(0.85)',
        // backgroundColor: 'rgba(255, 255, 255, 0.7)',
      }}
    >
      <Lottie options={defaultOptions} height={300} width={300} />{' '}
      {/* Increased size */}
    </div>
  );
};

export default Loader;
