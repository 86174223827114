import React from 'react';
import Image from 'next/image';
import { Grid, Card, CardContent, Box, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useRouter } from 'next/router';
import Job from '../public/assets/icons/job.png';

const JobComponent = ({ jobsReccomendation, icons }) => {
  const router = useRouter();
  
  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginTop: '20px',
      }}
      id='sign-up-show'
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Image
          src={Job}
          alt="Recommendation Icon"
          width={54}
          height={54}
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
        />
        <h4 style={{ margin: 0, fontWeight: '600', fontSize: '1.2rem' }}>
          Here are today's recommended internships
        </h4>
      </div>
      <div
        style={{
          borderBottom: '2px dotted #cccccc', 
          marginTop: '5px',
          marginBottom: '10px',
        }}
      ></div>
      <Grid 
        container 
        spacing={2}
        style={{ flexWrap: 'wrap' }}
      >
        {Boolean(jobsReccomendation?.length) &&
          jobsReccomendation.map((jobpost) => {
            const IconComponent =
              icons[jobpost.company_type] || AccountCircleIcon;

            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={jobpost.id}
                onClick={() => {
                  router.push(`/job/${jobpost?.id}`);
                }}
                style={{ width: '100%' }}
              >
                <Card
                  sx={{ 
                    borderRadius: 6, 
                    padding: '15px', 
                    boxShadow: 0,
                    minHeight: { xs: 'auto', md: '200px' }, 
                    width: '100%',
                  }}
                >
                  <CardContent
                    sx={{ 
                      padding: '10px', 
                      display: 'flex', 
                      flexDirection: 'column' 
                    }}
                  >
                    <Box display="flex" alignItems="center" mb={2}>
                      <IconComponent
                        style={{
                          color: '#427BF1',
                          fontSize: 35,
                          marginRight: 10,
                        }}
                      />
                      <Box>
                        <Typography
                          variant="h6"
                          component="div"
                          fontWeight="bold"
                          sx={{ fontSize: { xs: '14px', md: '18px' } }}
                        >
                          {jobpost?.job_domain.toUpperCase()}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{ 
                            color: '#596574', 
                            fontSize: { xs: '12px', md: '14px' } 
                          }}
                        >
                          {jobpost?.company_name.toUpperCase()}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ borderBottom: '1px dotted grey', mb: 2 }} />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      mt={1}
                      sx={{ fontSize: { xs: '12px', md: '14px' } }}
                    >
                      {jobpost?.location}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.primary"
                      mt={1}
                      sx={{ 
                        color: '#006DDD', 
                        fontWeight: 'bold', 
                        fontSize: { xs: '14px', md: '16px' } 
                      }}
                    >
                      {jobpost?.salary_range} / month
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mt={1}
                      sx={{ fontSize: { xs: '12px', md: '14px' } }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        {jobpost?.exp_reqd} years
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {jobpost?.date}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </section>
  );
};

export default JobComponent;
